var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-speed-dial',{staticClass:"mr-4",attrs:{"id":_vm.pageKey,"bottom":"","direction":"top","fixed":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"success darken-2","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',{attrs:{"color":"grey lighten-5"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"grey lighten-5"}},[_vm._v(" zmdi-apps ")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.sdaId != null)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.passRoute('sda', _vm.sdaId)}}},'v-btn',attrs,false),on),[_vm._v("SI")])]}}],null,false,4266580389)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.updateShippingInstruction')))])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.passRoute('payment', _vm.contractId)}}},'v-btn',attrs,false),on),[_vm._v("PAY")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('message.viewPayments')))])]),(_vm.packingListId != null)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.passRoute('packingList', _vm.packingListId)}}},'v-btn',attrs,false),on),[_vm._v("PL")])]}}],null,false,3898932931)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.updatePackingList')))])]):_vm._e(),(_vm.debitNoteId != null)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.passRoute('debitNote', _vm.debitNoteId)}}},'v-btn',attrs,false),on),[_vm._v("DN")])]}}],null,false,3181279189)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.updateDebitNote')))])]):_vm._e(),(_vm.invoiceId != null)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.passRoute('invoice', _vm.invoiceId)}}},'v-btn',attrs,false),on),[_vm._v("INV")])]}}],null,false,2813366222)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.updateInvoice')))])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.passRoute('shipment', _vm.contractId)}}},'v-btn',attrs,false),on),[_vm._v("SHP")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('message.updateShipment')))])]),(_vm.purchaseOrderId != null)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.passRoute('po', _vm.purchaseOrderId)}}},'v-btn',attrs,false),on),[_vm._v("PO")])]}}],null,false,4058904538)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.updatePurchaseOrder')))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }