<template>
    <v-speed-dial
        :id="pageKey"
        bottom
        class="mr-4"
        direction="top"
        fixed
        right
        v-model="fab"
    >
        <template v-slot:activator>
            <v-btn
                v-model="fab"
                color="success darken-2"
                fab
            >
                <v-icon v-if="fab" color="grey lighten-5">
                    mdi-close
                </v-icon>
                <v-icon v-else color="grey lighten-5">
                    zmdi-apps
                </v-icon>
            </v-btn>
        </template>
        <v-tooltip left v-if="sdaId != null">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small v-bind="attrs" v-on="on" @click="passRoute('sda', sdaId)">SI</v-btn>
            </template>
            <span>{{ $t('message.updateShippingInstruction') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small v-bind="attrs" v-on="on" @click="passRoute('payment', contractId)">PAY</v-btn>
            </template>
            <span>{{ $t('message.viewPayments') }}</span>
        </v-tooltip>
        <v-tooltip left v-if="packingListId != null">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small v-bind="attrs" v-on="on" @click="passRoute('packingList', packingListId)">PL</v-btn>
            </template>
            <span>{{ $t('message.updatePackingList') }}</span>
        </v-tooltip>
        <v-tooltip left v-if="debitNoteId != null">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small v-bind="attrs" v-on="on" @click="passRoute('debitNote', debitNoteId)">DN</v-btn>
            </template>
            <span>{{ $t('message.updateDebitNote') }}</span>
        </v-tooltip>
        <v-tooltip left v-if="invoiceId != null">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small v-bind="attrs" v-on="on" @click="passRoute('invoice', invoiceId)">INV</v-btn>
            </template>
            <span>{{ $t('message.updateInvoice') }}</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small v-bind="attrs" v-on="on" @click="passRoute('shipment', contractId)">SHP</v-btn>
            </template>
            <span>{{ $t('message.updateShipment') }}</span>
        </v-tooltip>
        <v-tooltip left v-if="purchaseOrderId != null">
            <template v-slot:activator="{ on, attrs }">
                <v-btn fab small v-bind="attrs" v-on="on" @click="passRoute('po', purchaseOrderId)">PO</v-btn>
            </template>
            <span>{{ $t('message.updatePurchaseOrder') }}</span>
        </v-tooltip>
    </v-speed-dial>
</template>

<script>
    import {api} from "Api";
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "FabContract",
        props: ['contractId'],
        data () {
            return {
                debitNoteId: null,
                fab: false,
                invoiceId: null,
                packingListId: null,
                pageKey: 0,
                purchaseOrderId: null,
                sdaId: null
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        },
        methods: {
            passRoute ( assetModule, assetId ) {
                switch( assetModule ){
                    case 'debitNote':
                        let tab = window.open('/v1/debitnotes/update/' + assetId,'_blank')
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                        break
                    case 'invoice':
                        tab = window.open('/v1/invoices/update/' + assetId,'_blank')
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                        break
                    case 'packingList':
                        tab = window.open('/v1/packinglists/update/' + assetId,'_blank')
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                        break
                    case 'payment':
                        tab = window.open('/v1/payments/update/contracts/' + assetId,'_blank')
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                        break
                    case 'po':
                        tab = window.open('/v1/purchaseorders/update/' + assetId,'_blank')
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                        break
                    case 'sda':
                        tab = window.open('/v1/sdas/update/' + assetId,'_blank')
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                        break
                    case 'shipment':
                        tab = window.open('/v1/shipments/update/' + assetId,'_blank')
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                        break
                }
            }
        },
        mounted() {
            let promise = new Promise((resolve, reject) => {
                    api
                        .get("/contracts/" + this.contractId + "/ids")
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
            try {
                promise
                    .then((data) => {
                        this.debitNoteId = data?.debitNoteId
                        this.invoiceId = data?.invoiceId
                        this.packingListId = data?.packingListId
                        this.purchaseOrderId = data?.purchaseOrderId
                        this.sdaId = data?.sdaId
                        this.pageKey = Math.floor(Math.random() * 100)
                    })
            } catch(err) {

            }
        }
    }
</script>

<style scoped>

</style>